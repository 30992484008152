<template>
    <b-container>
        <!-- <b-row class="justify-content-between">
            <b-col cols="12" sm="12" md="12" lg="12">
                <h2 class="title_forms txt_primary text-uppercase pl-3">Hoja de verificación de cirugía segura</h2>
            </b-col>
        </b-row> -->
        <b-container class="py-0">
            <p class="txt_secondary text-uppercase pt-3"><strong>Información de general</strong></p>
            <b-row>
                <b-col cols="12">
                    <b-table-simple class="table-sm">
                        <b-tbody>
                            <b-tr>
                                <b-th class="th_style">Hora de inicio de hoja</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Procedimiento a realizar</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Cirujano</b-th>
                                <b-td class="border_table">
                                    <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template>
                <b-breadcrumb class="breadcrumb-color">
                    <b-breadcrumb-item :to="{ name: 'entradaCirugia' }">
                        <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
                        <span class="txt_secondary text-uppercase">Entrada</span>
                    </b-breadcrumb-item>
                    <b-breadcrumb-item :to="{ name: 'pausaCirugia' }">
                        <span class="txt_secondary text-uppercase">Pausa quirúrgica</span>
                    </b-breadcrumb-item>
                    <b-breadcrumb-item :to="{ name: 'salidaCirugia' }">
                        <span class="txt_secondary text-uppercase">Salida</span>
                    </b-breadcrumb-item>
                </b-breadcrumb>
            </template>
            <b-row>
                <b-col cols="12">
                    <router-view />
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'registroCirujia',
    data: () => ({
        busy: false,
        paciente: {},
    }),
    methods: {
    }
}
</script>